import {useTranslation} from 'hooks/translations/useCustomTranslation';
import React, {forwardRef} from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {MenuItem} from '@mui/material';

import {useGetApiV1Stations} from 'lib/api/backend';
import {Station} from 'lib/api/backend.schemas';

import {useCustomAnchorPosition} from 'utils/forms/useCustomAnchorPosition';

import {Accordion, AccordionDetails, AccordionSummary} from 'components/basic-components';
import {FormInputText} from 'components/basic-components/Form/FormInputText';

import styles from './ContactForm.module.scss';

// eslint-disable-next-line react/display-name
export const MenuItemWithRef = forwardRef<any, any>((props, ref) => (
  <MenuItem {...props} ref={ref}>
    {props.children}
  </MenuItem>
));

export const FormInputStations = ({customAnchor}: {customAnchor?: boolean}) => {
  const {t} = useTranslation(['forms', 'common']);
  const {getSelectProps} = useCustomAnchorPosition({enabled: customAnchor});

  const {isLoading, data, isError} = useGetApiV1Stations();

  let stationOptions;
  let hiddenItems = [];

  if (!isLoading) {
    let d = data as Station[];
    const groupedData = d.reduce((acc, station) => {
      if (!acc[station.city]) {
        acc[station.city] = [];
      }
      acc[station.city].push(station);
      return acc;
    }, {}) as {[key: string]: Station[]};
    stationOptions = Object.entries(groupedData).map(([key, stationArr], index) => {
      const refMap = {};
      if (stationArr.length === 1) {
        const Ref = React.createRef();
        refMap[stationArr[0].id] = Ref;
        const hiddenRefOption = (
          <MenuItemWithRef
            ref={Ref}
            role="option"
            sx={{display: 'none'}}
            key={`${stationArr[0].id}-${index}`}
            value={stationArr[0].id}
          >
            {stationArr[0].description}
          </MenuItemWithRef>
        );
        hiddenItems.push(hiddenRefOption);
        return (
          <MenuItem
            onClick={() => refMap[stationArr[0].id]?.current.click()}
            key={`${key}-${index}`}
            value={stationArr[0].id}
            className={styles.menuItem}
          >
            {stationArr[0].description}
          </MenuItem>
        );
      } else {
        const hiddenMenuItems = stationArr.map((station, i) => {
          const Ref = React.createRef();
          refMap[station.id] = Ref;
          return (
            <MenuItemWithRef
              ref={Ref}
              role="option"
              sx={{display: 'none'}}
              key={`${station.id}-${i}`}
              value={station.id}
            >
              {station.description}
            </MenuItemWithRef>
          );
        });
        const res = stationArr.map((station, i) => {
          return (
            <MenuItem
              onClick={() => refMap[station.id]?.current.click()}
              role="option"
              sx={{marginLeft: 5}}
              key={`${station.id}-${i}`}
              value={station.id}
              className={styles.menuItem}
            >
              {station.description}
            </MenuItem>
          );
        });
        const accordion = (
          <Accordion
            sx={{marginBottom: 0, '&:before': {height: 0}}}
            disableGutters={true}
            variant="select"
          >
            <AccordionSummary
              variant="select"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{fontWeight: 700}}
            >
              {key}
            </AccordionSummary>
            <AccordionDetails>{res}</AccordionDetails>
          </Accordion>
        );
        hiddenItems = [...hiddenItems, ...hiddenMenuItems];
        return [accordion];
      }
    });
  }

  return (
    <FormInputText
      sx={{width: '100%', height: '100%', '& .MuiInputLabel-root': {zIndex: 0}}}
      select
      variant="standard"
      label={t('contacting')}
      placeholder={t('contacting')}
      name="receiver"
      key="receiver"
      SelectProps={getSelectProps()}
    >
      {[
        'contact.receiver.general',
        'contact.receiver.accounting',
        'contact.receiver.business_clients',
        'contact.receiver.feedback',
        'contact.receiver.press',
        'contact.receiver.fines_department',
        'contact.receiver.accident_replacement',
      ].map((item, index) => {
        return (
          <MenuItem key={`${item}-${index}`} value={item}>
            {t(item)}
          </MenuItem>
        );
      })}
      {!isLoading && !isError ? hiddenItems : null}
      {
        // dont show if loading of Stations failed
        !isLoading && !isError ? (
          <Accordion
            sx={{marginBottom: 0, '&:before': {height: 0}}}
            disableGutters={true}
            variant="select"
          >
            <AccordionSummary
              variant="select"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{fontWeight: 700}}
            >
              {t('stations', {ns: 'common'})}
            </AccordionSummary>
            <AccordionDetails>{stationOptions}</AccordionDetails>
          </Accordion>
        ) : null
      }
    </FormInputText>
  );
};
