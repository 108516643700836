import {useTranslation} from 'hooks/translations/useCustomTranslation';

import {FormInputText} from 'components/basic-components/Form/FormInputText';

export const FormInputReferenceNumber = ({required = false}) => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      label={t(required ? 'leaseInvoiceCustomerNumber' : 'optionalLeaseInvoiceCustomerNumber')}
      sx={{width: '100%', height: '100%'}}
      name="referenceNumber"
      key="referenceNumber"
    />
  );
};

export const FormInputMessage = ({rows = 6}) => {
  const {t} = useTranslation('forms');

  return (
    <FormInputText
      sx={{width: '100%', height: '100%'}}
      multiline
      rows={rows}
      label={t('yourMessage')}
      name="message"
      key="message"
    />
  );
};

export const FormInputCompany = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      sx={{width: '100%'}}
      label={t('optionalCompany')}
      name="company"
      key="company"
      autoComplete="organization"
    />
  );
};
