import * as yup from 'yup';

const phoneRegExp = /^[0-9]+$/;
const emailRegExp =
  /^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

const yupSchema = (t, isAuthenticated) => {
  const schema = yup.object({
    receiver: yup.string().required(t('fieldShouldntBeEmpty', {ns: 'forms'})),
    firstName: yup.string().required(t('fieldShouldntBeEmpty', {ns: 'forms'})),
    lastName: yup.string().required(t('fieldShouldntBeEmpty', {ns: 'forms'})),
    email: yup
      .string()
      .trim()
      .required(t('fieldShouldntBeEmpty', {ns: 'forms'}))
      .matches(emailRegExp, {message: t('invalidMail', {ns: 'forms'})}),
    salutation: yup.string().required(t('fieldShouldntBeEmpty', {ns: 'forms'})),
    message: yup.string().required(t('fieldShouldntBeEmpty', {ns: 'forms'})),
    phoneNumber: yup
      .string()
      .required(t('fieldShouldntBeEmpty', {ns: 'forms'}))
      .matches(phoneRegExp, t('invalidPhoneNumber', {ns: 'forms'}))
      .min(6, t('minLength', {ns: 'forms', minValue: 6})),
    acceptTerms: isAuthenticated
      ? yup.boolean()
      : yup
          .boolean()
          .required(t('acceptAgbAndDataPrivacy', {ns: 'forms'}))
          .oneOf([true], t('acceptAgbAndDataPrivacy', {ns: 'forms'})),
    captchaToken: yup.string().required(t('invalidCaptcha', {ns: 'forms'})),
    referenceNumber: yup.string().when('receiver', {
      is: val => ['contact.receiver.accounting', 'contact.receiver.fines_department'].includes(val),
      then: schema => schema.required(t('fieldShouldntBeEmpty', {ns: 'forms'})),
    }),
  });

  return schema;
};

export default yupSchema;
