import {FormProvider, useForm} from 'hooks/custom-react-hook-form';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {useBackgroundStyleConfigs} from 'hooks/useBackgroundStyleConfigs';
import {useSession} from 'next-auth/react';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import React from 'react';

import {yupResolver} from '@hookform/resolvers/yup';

import {usePostApiV1ContactRequest} from 'lib/api/backend';
import {PostApiV1ContactRequest400} from 'lib/api/backend.schemas';

import {palette} from 'styles/mui/scss';

import {Box, Button, Grid, Typography} from 'components/basic-components';
import {
  FormInputNewsletter,
  FormInputTerms,
} from 'components/section-components/reservation/step3/InputFields';

import {Captcha} from '../Captcha/Captcha';
import {
  FormInputEmail,
  FormInputFirstname,
  FormInputLastname,
  FormInputPhone,
  FormInputSalutation,
} from '../NewsletterForm/FormInputs';
import styles from './ContactForm.module.scss';
import {FormInputCompany, FormInputMessage, FormInputReferenceNumber} from './FormInputClient';
import {FormInputStations} from './FormInputStations';
import yupSchema from './getSchema';

const defaultValuesContactForm = {
  acceptTerms: false,
  email: '',
  firstName: '',
  lastName: '',
  message: '',
  phoneNumber: '',
  receiver: '',
  salutation: '',
  company: '',
  referenceNumber: '',
  subscribeNewsletter: false,
  captchaToken: '',
};

export const ContactForm = ({
  isInModal = false,
  onSuccess,
}: {
  isInModal?: boolean;
  onSuccess?: () => void;
}) => {
  const {t} = useTranslation(['forms', 'common']);
  const [showCaptcha, setShowCaptcha] = useState(true);

  const session = useSession();
  const isAuthenticated = session?.status === 'authenticated';
  const schema = yupSchema(t, isAuthenticated);
  const {push} = useRouter();
  const {mutate, isError, isSuccess, error} = usePostApiV1ContactRequest();

  useEffect(() => {
    if (isSuccess) {
      if (onSuccess) {
        onSuccess();
      }
      push(`/ueber-uns/kontakt/danke`);
    }
  }, [isSuccess, push]);

  const useFormMethods = useForm({
    name: 'ContactForm',
    defaultValues: defaultValuesContactForm,
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: {errors},
  } = useFormMethods;
  const onSubmit = data => {
    mutate({data: data});
  };

  useEffect(() => {
    if (isAuthenticated) {
      setValue('acceptTerms', true);
    } else {
      setValue('acceptTerms', false);
    }
  }, [isAuthenticated, setValue]);

  useEffect(() => {
    // set backend errors
    const errors = (error?.response?.data as PostApiV1ContactRequest400)?.errors;
    if (isError && errors) {
      Object.entries(errors).forEach(([key, value]) => {
        setError(key as any, {type: 'custom', message: value[0]});
      });
    }
  }, [isError, error]);

  const receiverValue = watch('receiver');

  useEffect(() => {
    if (errors?.captchaToken) {
      setShowCaptcha(true);
    }
  }, [errors?.captchaToken]);

  const {textColor, buttonStyle} = useBackgroundStyleConfigs();

  return (
    <Box
      sx={{
        padding: isInModal ? 0 : '50px 0',
        color: textColor,
        '& .MuiTextField-root *': {
          color: `${textColor} !important`,
        },
        '& .MuiTextField-root .MuiFormHelperText-root.Mui-error': {
          color: `${palette.colorError} !important`,
        },
        '& .MuiTextField-root .MuiInput-root': {
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `2px solid ${textColor}`,
          },
          '&.Mui-focused:before': {
            borderBottom: `2px solid ${palette.colorFocus}`,
          },
          '&:before': {
            borderBottom: `1px solid ${textColor}`,
          },
          '&.Mui-error:before': {
            borderBottom: `1px solid ${palette.colorError}`,
          },
          '&:after': {
            borderBottom: `1px solid ${textColor}`,
          },
        },
        '& .MuiFormControlLabel-root': {
          '& .MuiFormHelperText-root.Mui-error': {
            color: `${palette.colorError} !important`,
          },
          '& *': {
            color: `${textColor} !important`,
          },
        },
        '& .MuiFormControl-root': {
          '& .MuiFormHelperText-root.Mui-error': {
            color: `${palette.colorError} !important`,
          },
          '& *': {
            color: `${textColor} !important`,
          },
        },
      }}
    >
      <FormProvider {...useFormMethods}>
        <form
          id="contact-form"
          noValidate
          style={isInModal ? null : {marginTop: 30}}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography component="h3" sx={{marginBottom: '20px'}} className={styles.sectionTitle}>
            {t('yourData', {ns: 'common'})}
          </Typography>
          <Typography component="p" sx={{marginBottom: '20px'}}>
            {t('requiredData', {ns: 'common'})}
          </Typography>
          <Grid columnSpacing={'2%'} rowSpacing={5} container>
            <Grid xxs={12} sm={6}>
              <FormInputStations customAnchor={isInModal} />
            </Grid>
            <Grid xxs={12} sm={6}>
              <FormInputCompany />
            </Grid>
            <Grid xxs={12} sm={6}>
              <FormInputSalutation customAnchor={isInModal} />
            </Grid>
            <Grid xxs={12} sm={3}>
              <FormInputFirstname />
            </Grid>
            <Grid xxs={12} sm={3}>
              <FormInputLastname />
            </Grid>
            <Grid xxs={12} sm={6}>
              <FormInputEmail />
            </Grid>
            <Grid xxs={12} sm={6}>
              <FormInputPhone />
            </Grid>
            {[
              'contact.receiver.accounting',
              'contact.receiver.feedback',
              'contact.receiver.fines_department',
            ].includes(receiverValue) ? (
              <>
                <Grid xxs={12}>
                  <FormInputReferenceNumber
                    required={[
                      'contact.receiver.accounting',
                      'contact.receiver.fines_department',
                    ].includes(receiverValue)}
                  />
                </Grid>
              </>
            ) : null}
            <Grid xxs={12}>
              <FormInputMessage rows={3} />
            </Grid>
            <Grid xxs={12}>
              <FormInputNewsletter />
            </Grid>
            {isAuthenticated ? null : (
              <Grid xxs={12}>
                <FormInputTerms />
              </Grid>
            )}
            {showCaptcha ? (
              <Grid xxs={12}>
                <Captcha />
              </Grid>
            ) : (
              <Captcha size="invisible" />
            )}
            <Grid xxs={12}>
              <Button variant={buttonStyle.variant} sx={{float: 'right'}} type="submit">
                {t('submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};
export default ContactForm;
